<template>
  <div>
    <div class="bg-white">
      <div class="row no-gutters p-20 p-lg-0">
        <div class="col-md-12 col-lg-12">
          <div class="content-heading d-flex justify-content-between align-items-center p-10">
            <h3 class="text-primary font-22"></h3>
            <div class="d-lg-flex d-md-block d-block mt-md-10">
              <div class="dropdown custom-dropdown mr-50 mb-md-10 mb-20 mb-lg-0">
                <button id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                        data-toggle="dropdown" type="button">
                  {{ searchDict.searchField.header || 'Select column' }}
                </button>
                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu"
                     style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 40px, 0px);"
                     x-placement="bottom-start">
                  <a v-show="searchDict.searchField" class="dropdown-item" @click="selectColumn('')">Select column</a>
                  <a v-for="(searchConfig,_index) in tableSearchConfig" :key="_index"
                     class="dropdown-item"
                     @click="selectColumn(searchConfig)">{{ searchConfig.header }}</a>
                </div>
              </div>
              <div class="search-form">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'text'"
                       v-model.trim="searchDict.searchText"
                       class="form-control"
                       placeholder="Search" type="text" @keyup.enter="applySearch()">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                       v-model.trim="searchDict.fromDate"
                       class="form-control mr-2" type="date" @keyup.enter="applySearch()">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date'"
                       v-model.trim="searchDict.date"
                       class="form-control"
                       type="date" @keyup.enter="applySearch()">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                       v-model.trim="searchDict.toDate"
                       class="form-control"
                       type="date" @keyup.enter="applySearch()">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number'"
                       v-model.trim="searchDict.searchText"
                       class="form-control"
                       placeholder="Search" type="number" @keyup.enter="applySearch()">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                       v-model.trim="searchDict.fromNumber"
                       class="form-control mr-2" placeholder="From" type="number" @keyup.enter="applySearch()">
                <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                       v-model.trim="searchDict.toNumber"
                       class="form-control" placeholder="To" type="number" @keyup.enter="applySearch()">
                <input v-show="!searchDict.searchField" v-model.trim="searchDict.searchText"
                       class="form-control" placeholder="Search" type="text" @keyup.enter="applySearch()">
                <div>
                  <button class="btn btn-primary" @click="applySearch()">
                    <img alt="Search" src="@/assets/img/icons/search-glass.svg">
                  </button>
                  <button class="btn btn-outline-primary ml-2" @click="clearSearch()">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="p-10">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th v-for="(item, _i) in tableHeaders" :key="_i">{{ item.title }} <br> {{ item.subtitle }}</th>
                </tr>
                </thead>
                <tbody v-show="loading">
                <tr>
                  <td :colspan="tableHeaders.length">
                    <FBTableLoader :columns="tableHeaders.length" :height="600" :primaryColor="'#d3d3d3'" :rows="5"
                                   class="col-12"></FBTableLoader>
                  </td>
                </tr>
                </tbody>
                <tbody v-show="!loading">
                <tr v-for="(rowData, _rowIndex) in tableData" :key="_rowIndex">
                  <td v-for="(item, _i) in rowData" :key="_i">
                    <span v-html="item"></span>
                  </td>
                </tr>
                <tr v-show="tableData.length === 0">
                  <td :colspan="tableHeaders.length">No data available.</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="container pl-0 pr-0">
              <div class="">
                <div class="d-lg-flex d-block justify-content-between mt-15 mb-45">
                  <div>
                    <select v-model="paginateDict.dataPerPage" class="form-control admin-input justify-content-start"
                            @change="getContentsFromServer()">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div>
                    <nav aria-label="Page navigation example">
                      <ul class="pagination pagination-lg justify-content-end">
                        <li :class="{disabled: !paginateDict.firstPage, 'page-item':true}">
                          <a class="page-link" @click="getPaginatedData(paginateDict.firstPage)">First</a>
                        </li>
                        <li v-show="paginateDict.previousPageNumber > 0"
                            :class="{disabled: !paginateDict.previousUrl, 'page-item':true}">
                          <a class="page-link"
                             @click="getPaginatedData(paginateDict.previousUrl)">{{
                              paginateDict.previousPageNumber
                            }}</a>
                        </li>
                        <li class="page-item disabled"><a class="page-link" href="#">{{
                            paginateDict.currentPageNumber
                          }}</a>
                        </li>
                        <li v-show="paginateDict.nextPageNumber > 0"
                            :class="{disabled: !paginateDict.nextUrl, 'page-item':true}">
                          <a class="page-link" @click="getPaginatedData(paginateDict.nextUrl)">{{
                              paginateDict.nextPageNumber
                            }}</a>
                        </li>
                        <li :class="{disabled: !paginateDict.lastPage, 'page-item':true}">
                          <a class="page-link" @click="getPaginatedData(paginateDict.lastPage)">Last</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FBTableLoader } from '@/plugins/content-loader/core/components'
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import moment from 'moment'
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ContractsTable',
  props: {
    tableHeaders: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    isDataFetched: {
      type: Boolean,
      default: false
    },
    tableSearchConfig: {
      type: Array,
      required: true
    },
    apiPrefix: {
      type: String,
      required: true
    },
    dispatchedStore: {
      type: String,
      required: true
    },
    committedStore: {
      type: String,
      required: true
    },
    getterStore: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      searchDict: {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 0,
        nextPageNumber: 0
      },
      loading: false
    }
  },
  components: { FBTableLoader },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    searchDict: {
      searchField: {
        required
      }
    }
  },
  methods: {
    selectColumn (searchConfig) {
      this.searchDict = {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }
      this.searchDict.searchField = searchConfig
    },
    clearSearch () {
      this.searchDict = {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }
      this.getContentsFromServer()
    },
    generatePagination (data) {
      try {
        this.paginateDict.nextUrl = _.get(data, 'next') || null
        this.paginateDict.previousUrl = _.get(data, 'previous') || null
        this.paginateDict.totalPage = _.get(data, 'total_page') || 0
        this.paginateDict.nextOffset = _.get(data, 'next_offset') || 0
        this.paginateDict.currentPage = _.get(data, 'current_page') || 0
        this.paginateDict.totalCount = _.get(data, 'count') || 0
        this.paginateDict.remainingCount = _.get(data, 'remaining_count') || 0
        this.paginateDict.firstPage = `/api/${this.apiPrefix}/?limit=${this.paginateDict.dataPerPage}`
        let offset = 0
        for (let i = 1; i < this.paginateDict.totalPage; i++) {
          offset += _.parseInt(this.paginateDict.dataPerPage)
        }
        this.paginateDict.lastPage = `/api/${this.apiPrefix}/?offset=${offset}&limit=${this.paginateDict.dataPerPage}`
        // Page number
        this.paginateDict.previousPageNumber = this.paginateDict.previousUrl ? this.paginateDict.currentPage - 1 : 0
        this.paginateDict.currentPageNumber = this.paginateDict.currentPage
        this.paginateDict.nextPageNumber = this.paginateDict.nextUrl ? this.paginateDict.currentPage + 1 : 0
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getPaginatedData (apiUrl) {
      this.loading = true
      const response = await this.$store.dispatch(this.dispatchedStore, { apiUrl: apiUrl })
      const data = response.data
      if (data.success) {
        this.generatePagination(data)
        this.$store.commit(this.committedStore, data.results)
        await this.prepareTableContents()
      } else {
        this.errorLog(data)
      }
      this.loading = false
    },
    prepareTableContents () {
      this.$emit('updatedTableContents', true)
    },
    async getContentsFromServer () {
      try {
        this.loading = true
        const limit = this.paginateDict.dataPerPage
        const apiUrl = `/api/${this.apiPrefix}/?search=1&offset=0&limit=${limit}`
        const response = await this.$store.dispatch(this.dispatchedStore, { apiUrl: apiUrl })
        const data = response.data
        if (data.success) {
          this.generatePagination(data)
          this.$store.commit(this.committedStore, data.results)
          await this.prepareTableContents()
        } else {
          this.errorLog(data)
        }
        this.loading = false
      } catch (e) {
        this.errorLog(e)
      }
    },
    async applySearch () {
      const vm = this
      this.$v.searchDict.$touch()
      if (this.$v.searchDict.$invalid) {
        this.$notify({
          title: '',
          text: 'Please select column.',
          type: 'error',
          duration: 5000
        })
      } else {
        if (vm.searchDict.searchField.widget_type === 'text' || vm.searchDict.searchField.widget_type === 'number') {
          try {
            vm.loading = true
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchParams = `${vm.searchDict.searchField.db_representation}=${vm.searchDict.searchText}`
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch(this.dispatchedStore, { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit(this.committedStore, data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
        if (vm.searchDict.searchField.widget_type === 'date_range') {
          try {
            vm.loading = true
            const fromDate = moment(vm.searchDict.fromDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 00:00:00'
            const toDate = moment(vm.searchDict.toDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 00:00:00'
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchKeys = _.split(vm.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              searchParams = `${_from}=${fromDate},${toDate}&${_to}=${fromDate},${toDate}`
            }
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch(this.dispatchedStore, { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit(this.committedStore, data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
        if (vm.searchDict.searchField.widget_type === 'number_range') {
          try {
            vm.loading = true
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchKeys = _.split(vm.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              const _fromNumber = vm.searchDict.fromNumber || 0
              const _toNumber = vm.searchDict.toNumber || _fromNumber
              searchParams = `${_from}=${_fromNumber},${_toNumber}&${_to}=${_fromNumber},${_toNumber}`
            }
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch(this.dispatchedStore, { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit(this.committedStore, data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
      }
    }
  },
  created () {
    this.getContentsFromServer()
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <AllCountryGreetings></AllCountryGreetings>
    <AllCountryContracts></AllCountryContracts>
    <AllCountryBillOfLading></AllCountryBillOfLading>
    <AllCountryContainer></AllCountryContainer>
    <AllCountryContractRegionGroup></AllCountryContractRegionGroup>
    <SecondaryContracts></SecondaryContracts>
    <ImporterContractRefine></ImporterContractRefine>
  </div>
</template>

<script>
import AllCountryContracts from '@/components/admin/traceability/all-country/AllCountryContracts'
import AllCountryGreetings from '@/components/admin/traceability/all-country/AllCountryGreetings'
import AllCountryBillOfLading from '@/components/admin/traceability/all-country/AllCountryBillOfLading'
import AllCountryContainer from '@/components/admin/traceability/all-country/AllCountryContainer'
import AllCountryContractRegionGroup from '@/components/admin/traceability/all-country/AllCountryContractRegionGroup'
import SecondaryContracts from '@/components/admin/traceability/all-country/SecondaryContracts'
import ImporterContractRefine from '@/components/admin/traceability/all-country/ImporterContractRefine'

export default {
  name: 'AllCountry',
  components: {
    AllCountryContracts,
    SecondaryContracts,
    ImporterContractRefine,
    AllCountryGreetings,
    AllCountryBillOfLading,
    AllCountryContainer,
    AllCountryContractRegionGroup
  }
}
</script>

<style scoped>

</style>

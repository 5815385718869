<template>
  <div>
    <h3 class="Contracts mt-30 mb-25">Contract Region Group (Direct sales)</h3>
    <div class="bg-white py-40">
      <div class="row p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group">
            <div class="form-row">
              <div class="col-lg-3 col-md-12">
                <label class="col-form-label">Contract Region Group</label>
              </div>
              <div class="col-lg-7 col-md-12">
                <div class="form-group">
                  <div class="drop drop-inline drop-sm">
                    <div class="cont">
                      <img alt=""
                           src="@/assets/img/icons/upload1.svg">
                      <div v-if="!contractRegionGroupForm.file.tsync_id" class="desc"> Upload Excel File</div>
                      <div v-if="contractRegionGroupForm.file.tsync_id" class="desc">
                        {{ contractRegionGroupForm.file.name }}
                      </div>
                    </div>
                    <input ref="fileUploader" accept=".xls, .xlsx"
                           type="file"
                           @change="onExcelChange($event)" @click="resetFileUploader">
                    <div
                      v-if="$v.$error & !$v.contractRegionGroupForm.file.tsync_id.required"
                      class="error">
                      An excel file is required.
                    </div>
                  </div>
                  <div class="d-inline-flex">
                    <i v-show="contractRegionGroupForm.file.tsync_id" aria-hidden="true"
                       class="fa fa-times ml-2 clear-file"
                       @click="clearFile(contractRegionGroupForm)"> Clear</i>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-12">
                <div class="text-right">
                  <div class="d-inline-flex">
                    <button :disabled="!Object.keys(contractRegionGroupContents).length || !isSaveButtonActive"
                            v-if="!isObserverUser" class="btn btn-submit"
                            type="button"
                            @click="saveContractRegionGroup"> {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <h4 v-show="fileLoading" class="text-warning">
                  <span v-show="!Object.keys(contractRegionGroupContents).length">
                    Excel is loading. Please wait a moment
                  </span>
                </h4>
                <h6 v-show="uploadPercentage"
                    :class="uploadPercentage === 100 ? 'text-success':'text-warning'">
                    <span v-show="uploadPercentage!==100">
                     Please don't close this TAB before the upload is 100% completed.
                    </span>
                </h6>
                <div v-show="uploadPercentage && uploadPercentage!==100" class="progress">
                  <div :style="'width: '+uploadPercentage+'%'" aria-valuemax="100"
                       aria-valuemin="0" aria-valuenow="1" class="progress-bar progress-bar-striped bg-success"
                       role="progressbar">{{ uploadPercentage }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-lg-0 p-20">
        <div class="col-md-12">
          <div class="p-3">
            <Table
              :api-prefix="apiPrefix"
              :committed-store="'setTraceabilityContractRegionGroupsContent'"
              :dispatched-store="'getContractRegionGroupsFromServer'"
              :is-data-fetched="loading"
              :table-data="tableData"
              :table-headers="tableHeaders"
              :table-search-config="tableSearchConfig"
              @updatedTableContents="getTableData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import { required } from 'vuelidate/lib/validators'
import xlsx from 'xlsx'
import Table from '@/components/admin/traceability/Table'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'
import _ from 'lodash'

export default {
  name: 'AllCountryContractRegionGroup',
  components: { Table },
  data () {
    return {
      contractRegionGroupForm: {
        file: {
          file: null,
          name: null,
          tsync_id: null
        }
      },
      contractRegionGroupContents: {},
      containerRegionGroups: {},
      tableHeaders: [
        {
          title: 'Customer ID',
          subtitle: ''
        },
        {
          title: 'Customer',
          subtitle: ''
        },
        {
          title: 'Importer ID',
          subtitle: ''
        },
        {
          title: 'Importer',
          subtitle: ''
        },
        {
          title: 'Contract',
          subtitle: 'No'
        },
        {
          title: 'Farmer',
          subtitle: 'Region'
        },
        {
          title: 'Farmer',
          subtitle: 'Group/Label'
        },
        {
          title: 'Farmer',
          subtitle: 'Subgroup'
        },
        {
          title: 'Purchase',
          subtitle: 'Point'
        },
        {
          title: 'Purchase',
          subtitle: 'Point GPS'
        },
        {
          title: 'Delivery',
          subtitle: 'Share'
        }
      ],
      tableSearchConfig: [
        {
          header: 'Customer ID',
          db_representation: 'contract__customer__public_code',
          widget_type: 'text'
        },
        {
          header: 'Customer',
          db_representation: 'contract__customer__name',
          widget_type: 'text'
        },
        {
          header: 'Importer ID',
          db_representation: 'contract__importer__public_code',
          widget_type: 'text'
        },
        {
          header: 'Importer',
          db_representation: 'contract__importer__name',
          widget_type: 'text'
        },
        {
          header: 'Contract No',
          db_representation: 'contract__contract_no',
          widget_type: 'text'
        },
        {
          header: 'Farmer Region',
          db_representation: 'region__name',
          widget_type: 'text'
        },
        {
          header: 'Farmer Group/Label',
          db_representation: 'group__name',
          widget_type: 'text'
        },
        {
          header: 'Farmer Subgroup',
          db_representation: 'sub_group__name',
          widget_type: 'text'
        },
        {
          header: 'Purchase Point',
          db_representation: 'purchase_point__name',
          widget_type: 'text'
        },
        {
          header: 'Purchase Point GPS',
          db_representation: 'purchase_point__location__latitude__gte,purchase_point__location__longitude__lte',
          widget_type: 'number_range'
        },
        {
          header: 'Delivery Share',
          db_representation: 'delivery_share__gte,delivery_share__lte',
          widget_type: 'text'
        }
      ],
      searchDict: {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 0,
        nextPageNumber: 0
      },
      apiPrefix: 'v1/contract-region-groups',
      tableData: [],
      loading: false,
      fileLoading: false,
      fileTsyncIdMapping: {},
      loadingText: 'Excel is loading. Please wait a moment',
      isSaveButtonActive: true,
      saveButtonTxt: 'Submit',
      uploadPercentage: 0
    }
  },
  computed: {
    containerRegionGroupsContent () {
      return this.$store.getters.getTraceabilityAllCountryContractRegionGroupsContents
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    contractRegionGroupForm: {
      file: {
        tsync_id: { required }
      }
    }
  },
  methods: {
    resetFileUploader () {
      // It reset image or file uploader if same file selected again
      this.$refs.fileUploader.value = ''
    },
    onExcelChange (e) {
      this.contractRegionGroupContents = {}
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.contractRegionGroupForm.file.file = file
        this.contractRegionGroupForm.file.name = file.name
        this.contractRegionGroupForm.file.tsync_id = this.uuidV4()
        this.fileLoading = false
        this.prepareContractRegionGroupData()
        this.fileLoading = true
      } else {
        // if excel upload widget clicked and no file selected
        this.contractRegionGroupForm.file.file = null
        this.contractRegionGroupForm.file.name = null
        this.isChartPreviewed = false
        this.contractRegionGroupForm.file.tsync_id = null
      }
    },
    prepareContractRegionGroupData () {
      if (this.contractRegionGroupForm.file.file) {
        const file = this.contractRegionGroupForm.file.file
        const fileReader = new FileReader()
        fileReader.onload = ev => {
          try {
            const data = ev.target.result
            const XLSX = xlsx
            const workbook = XLSX.read(data, {
              type: 'binary'
            })
            const wsname = workbook.SheetNames[0] // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])

            const excelData = [] // Clear received data
            //
            const wsLength = ws.length
            for (var i = 0; i < wsLength; i++) {
              excelData.push(ws[i])
            }
            this.contractRegionGroupContents = excelData
          } catch (e) {
            return console.log('File can\'t read!')
          }
        }
        fileReader.readAsBinaryString(file)
      }
    },
    getTableData (payload) {
      if (payload) {
        payload = this.$store.getters.getTraceabilityAllCountryContractRegionGroupsContents
      }
      this.tableData = []
      for (const _data of payload) {
        const tempData = []
        tempData.push(_.get(_data, 'contract.customer.public_code') || 'N/A')
        tempData.push(_.get(_data, 'contract.customer.name') || 'N/A')
        tempData.push(_.get(_data, 'contract.importer.public_code') || 'N/A')
        tempData.push(_.get(_data, 'contract.importer.name') || 'N/A')
        tempData.push(_.get(_data, 'contract_no') || 'N/A')
        tempData.push(_.get(_data, 'region.name') || 'N/A')
        tempData.push(_.get(_data, 'group.name') || 'N/A')
        tempData.push(_.get(_data, 'sub_group.name') || 'N/A')
        tempData.push(_.get(_data, 'purchase_point.name') || 'N/A')
        const purPointLocation = _.get(_data, 'purchase_point.location') || null
        if (purPointLocation) {
          tempData.push(purPointLocation.latitude + ', ' + purPointLocation.longitude)
        } else {
          tempData.push('N/A')
        }
        tempData.push(_.get(_data, 'delivery_share') || 'N/A')
        // tempData.push('<a href="#">Demo Info</a>')
        this.tableData.push(tempData)
      }
      return this.tableData
    },
    async saveContractRegionGroup () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'
        const vm = this

        // start file upload log
        const fileUploadLogPromises = []
        const response = this.createFileUploadLog(this.contractRegionGroupForm)
        fileUploadLogPromises.push(response)

        Promise.all(fileUploadLogPromises).then((values) => {
          const filePromises = []
          values.map((item) => {
            const fileId = item.data.file ? item.data.file.id : 0
            const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
            if (fileId === 0 || fileTsyncId === '') return
            const file = this.fileTsyncIdMapping[fileTsyncId]
            if (!file) return
            const response = this.uploadFile(fileId, file)
            filePromises.push(response)
          })
          Promise.all(filePromises).then((fileResponses) => {
            // this.$notify({
            //   title: '',
            //   text: 'File upload log created successfully.',
            //   type: 'success', // 'warn', 'error', 'success'
            //   duration: 5000
            // })
            this.$forceUpdate()
          })
        })
        // end file upload log

        let dataPosted = 0
        let dataNotPosted = 0
        const tempData = []

        const contractRegionGroupPromises = []
        let contractRegionGroupUploadCount = 0
        const totalContractRegionGroup = _.size(vm.contractRegionGroupContents)
        const promises = await vm.contractRegionGroupContents.reduce(async (memo, v) => {
          await memo
          const response = vm.submitEachContractRegionGroup(v)
          var myPromise = this.MakeQuerablePromise(response)
          response.then(function (data) {
            if (myPromise.isFulfilled()) {
              contractRegionGroupUploadCount += 1
              vm.uploadPercentage = Math.ceil(contractRegionGroupUploadCount / totalContractRegionGroup * 100)
            }
          })
          contractRegionGroupPromises.push(response)
        }, [])
        _.unset(promises, '')

        Promise.all(contractRegionGroupPromises).then((values) => {
          values.map((item) => {
            const data = item.data
            if (data.success) {
              if (!_.get(data, 'results')) {
                tempData.push(data)
              } else {
                this.$store.commit('setTraceabilityContractRegionGroupsContent', data.results)
              }
              dataPosted += 1
            } else {
              this.$notify({
                title: 'Contract Region Group',
                text: data.message,
                type: 'error', // 'warn', 'error', 'success'
                duration: 10000
              })
              dataNotPosted += 1
              vm.errorLog(item)
            }
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'

          if (tempData.length > 0) {
            vm.$store.commit('setTraceabilityContractRegionGroupsContent', tempData)
            this.getTableData(tempData)
          }
          if (dataNotPosted > 0) {
            this.$notify({
              title: 'Region',
              text: `${dataNotPosted} region not created/ updated.`,
              type: 'error', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
          if (dataPosted > 0) {
            this.$notify({
              title: 'Region',
              text: `${dataPosted} region created/ updated successfully.`,
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
        })
      }
    },
    async submitEachContractRegionGroup (data) {
      const formData = this.$_.cloneDeep(data)
      return await this.$store.dispatch('createOrUpdateTraceabilityContractRegionGroupsContent', formData)
    },
    async createFileUploadLog (data) {
      const formData = this.$_.cloneDeep(data)
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : this.uuidV4()
      this.fileTsyncIdMapping[fileTsyncId] = data.file.file
      if (this.contractRegionGroupForm.file.file) {
        if (!formData.id) {
          formData.file = {
            tsync_id: fileTsyncId,
            extension: formData.file.name.split('.').pop()
          }
        } else {
          try {
            formData.file.extension = formData.file.name.split('.').pop()
          } catch (e) {
            this.errorLog(e)
          }
        }
      }
      formData.content_type = ContentTypes.FILE
      formData.content = this.$store.getters.getContentByAppModule(Menus.traceability.key).id
      return await this.$store.dispatch('createOrUpdateTraceabilityContents', formData)
    }
  },
  watch: {
    containerRegionGroupsContent: {
      deep: true,
      handler (newValue) {
        this.containerRegionGroups = _.cloneDeep(newValue)
      }
    }
  },
  created () {
    window.onbeforeunload = () => (this.uploadPercentage ? true : null)
  }
}
</script>

<style lang="scss" scoped>

.clear-file {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 100%; // if file name is long than it wont overflow next close button
}
</style>

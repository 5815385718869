<template>
  <div v-show="!loading">
    <h3 class="Contracts mt-30 mb-25">Contracts (Direct sales)</h3>
    <div class="bg-white py-40">
      <div class="row p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group">
            <div class="form-row">
              <div class="col-lg-3 col-md-12">
                <label class="col-form-label">Contract of Coffee shipment</label>
              </div>
              <div class="col-lg-7 col-md-12">
                <div class="form-group">
                  <div class="drop drop-inline drop-sm">
                    <div class="cont">
                      <img alt=""
                           src="@/assets/img/icons/upload1.svg">
                      <div v-if="!contractForm.file.tsync_id" class="desc"> Upload Excel File</div>
                      <div v-if="contractForm.file.tsync_id" class="desc">{{ contractForm.file.name }}</div>
                    </div>
                    <input ref="fileUploader"
                           accept=".xls, .xlsx" type="file"
                           @change="onExcelChange($event)" @click="resetFileUploader">
                    <div
                      v-if="$v.$error & !$v.contractForm.file.tsync_id.required"
                      class="error">
                      An excel file is required.
                    </div>
                  </div>
                  <div class="d-inline-flex">
                    <i v-show="contractForm.file.tsync_id" aria-hidden="true"
                       class="fa fa-times ml-2 clear-file"
                       @click="clearFile(contractForm)"> Clear</i>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-12">
                <div class="text-right">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser"
                            :disabled="!Object.keys(contractContents).length || !isSaveButtonActive"
                            class="btn btn-submit"
                            type="button"
                            @click="saveContracts">
                      <span v-show="!isSaveButtonActive" aria-hidden="true" class="spinner-border spinner-border-sm"
                            role="status"></span>
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <h4 v-show="fileLoading" class="text-warning">
                  <span v-show="!Object.keys(contractContents).length">
                    Excel is loading. Please wait a moment
                  </span>
                </h4>
                <h6 v-show="uploadPercentage"
                    :class="uploadPercentage === 100 ? 'text-success':'text-warning'">
                    <span v-show="uploadPercentage!==100">
                     Please don't close this TAB before the upload is 100% completed.
                    </span>
                </h6>
                <div v-show="uploadPercentage && uploadPercentage!==100" class="progress">
                  <div :style="'width: '+uploadPercentage+'%'" aria-valuemax="100"
                       aria-valuemin="0" aria-valuenow="1" class="progress-bar progress-bar-striped bg-success"
                       role="progressbar">{{ uploadPercentage }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-lg-0 p-20">
        <div class="col-md-12">
          <div class="p-3">
            <Table
              :api-prefix="apiPrefix"
              :committed-store="'setTraceabilityContractsContent'"
              :dispatched-store="'getContractsFromServer'"
              :is-data-fetched="loading"
              :table-data="tableData"
              :table-headers="tableHeaders"
              :table-search-config="tableSearchConfig"
              @updatedTableContents="getTableData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import { required } from 'vuelidate/lib/validators'
import xlsx from 'xlsx'
import moment from 'moment'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'
import Table from '@/components/admin/traceability/Table'
import _ from 'lodash'
import CommonHelper from '@/utils/common'
import APIHelper from '@/service/api-helper'

export default {
  name: 'AllCountryContracts',
  components: { Table },
  data () {
    return {
      contractForm: {
        file: {
          file: null,
          name: null,
          tsync_id: null
        }
      },
      contractContents: {},
      contracts: {},
      tableHeaders: [
        {
          title: 'Customer ID',
          subtitle: ''
        },
        {
          title: 'Customer',
          subtitle: ''
        },
        {
          title: 'Importer ID',
          subtitle: ''
        },
        {
          title: 'Importer',
          subtitle: ''
        },
        {
          title: 'Contract',
          subtitle: 'No'
        },
        {
          title: 'Shipment',
          subtitle: ''
        },
        {
          title: 'Exporter ID',
          subtitle: ''
        },
        {
          title: 'Exporter',
          subtitle: ''
        },
        {
          title: 'Country ID',
          subtitle: ''
        },
        {
          title: 'Country',
          subtitle: ''
        },
        {
          title: 'Weight',
          subtitle: ''
        },
        {
          title: 'Bloom',
          subtitle: 'Share'
        },
        {
          title: 'Contract',
          subtitle: 'Quality'
        },
        {
          title: 'Coinvest Farmer',
          subtitle: ''
        },
        {
          title: 'Coinvest FSU',
          subtitle: ''
        }
      ],
      tableSearchConfig: [
        {
          header: 'Customer ID',
          db_representation: 'customer__public_code',
          widget_type: 'text'
        },
        {
          header: 'Customer',
          db_representation: 'customer__name',
          widget_type: 'text'
        },
        {
          header: 'Importer ID',
          db_representation: 'importer__public_code',
          widget_type: 'text'
        },
        {
          header: 'Importer',
          db_representation: 'importer__name',
          widget_type: 'text'
        },
        {
          header: 'Contract No',
          db_representation: 'contract_no',
          widget_type: 'text'
        },
        {
          header: 'Shipment',
          db_representation: 'shipment_start_date,shipment_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Exporter ID',
          db_representation: 'exporter__public_code',
          widget_type: 'text'
        },
        {
          header: 'Exporter',
          db_representation: 'exporter__name',
          widget_type: 'text'
        },
        {
          header: 'Country ID',
          db_representation: 'exporter__parent__public_code',
          widget_type: 'text'
        },
        {
          header: 'Country',
          db_representation: 'exporter__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Weight',
          db_representation: 'weight__gte,weight__lte',
          widget_type: 'number_range'
        },
        {
          header: 'Bloom Share',
          db_representation: 'bloom_share__gte,bloom_share__lte',
          widget_type: 'number_range'
        },
        {
          header: 'Contract Quality',
          db_representation: 'contract_quality',
          widget_type: 'text'
        },
        {
          header: 'Coinvest Farmer',
          db_representation: 'co_invest_farmer__gte,co_invest_farmer__lte',
          widget_type: 'number_range'
        },
        {
          header: 'Coinvest FSU',
          db_representation: 'co_invest_fsu__gte,co_invest_fsu__lte',
          widget_type: 'number_range'
        }
      ],
      searchDict: {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 0,
        nextPageNumber: 0
      },
      apiPrefix: 'v1/contracts',
      tableData: [],
      contractImportObject: {},
      loading: false,
      fileLoading: false,
      fileTsyncIdMapping: {},
      loadingText: 'Excel is loading. Please wait a moment',
      isSaveButtonActive: true,
      saveButtonTxt: 'Submit',
      uploadPercentage: 0
    }
  },
  computed: {
    contractsContent () {
      return this.$store.getters.getTraceabilityAllCountryContractsContents
    }
  },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  validations: {
    contractForm: {
      file: {
        tsync_id: { required }
      }
    }
  },
  methods: {
    resetFileUploader () {
      // It reset image or file uploader if same file selected again
      this.$refs.fileUploader.value = ''
    },
    onExcelChange (e) {
      this.contractContents = {}
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.contractForm.file.file = file
        this.contractForm.file.name = file.name
        this.contractForm.file.tsync_id = this.uuidV4()
        this.fileLoading = false
        this.prepareContractsData()
        this.fileLoading = true
      } else {
        // if excel upload widget clicked and no file selected
        this.contractForm.file.file = null
        this.contractForm.file.name = null
        this.isChartPreviewed = false
        this.contractForm.file.tsync_id = null
      }
      // this.$forceUpdate()
    },
    prepareContractsData () {
      if (this.contractForm.file.file) {
        const file = this.contractForm.file.file
        const fileReader = new FileReader()
        fileReader.onload = ev => {
          try {
            const data = ev.target.result
            const XLSX = xlsx
            const workbook = XLSX.read(data, {
              type: 'binary'
            })
            const wsname = workbook.SheetNames[0] // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])

            const excelData = [] // Clear received data
            //
            const wsLength = ws.length
            for (var i = 0; i < wsLength; i++) {
              excelData.push(ws[i])
            }
            this.contractContents = excelData
          } catch (e) {
            return console.log('File can\'t read!')
          }
        }
        fileReader.readAsBinaryString(file)
      }
    },
    async saveContracts () {
      this.contractImportObject = {}
      const vm = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'
        const vm = this

        // start file upload log
        const fileUploadLogPromises = []
        const response = this.createFileUploadLog(this.contractForm)
        fileUploadLogPromises.push(response)

        Promise.all(fileUploadLogPromises).then((values) => {
          const filePromises = []
          values.map((item) => {
            const fileId = item.data.file ? item.data.file.id : 0
            const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
            if (fileId === 0 || fileTsyncId === '') return
            const file = this.fileTsyncIdMapping[fileTsyncId]
            if (!file) return
            const response = this.uploadFile(fileId, file)
            filePromises.push(response)
          })
          Promise.all(filePromises).then((fileResponses) => {
            // this.$notify({
            //   title: '',
            //   text: 'File upload log created successfully.',
            //   type: 'success', // 'warn', 'error', 'success'
            //   duration: 5000
            // })
            this.$forceUpdate()
          })
        })
        // end file upload log

        let dataPosted = 0
        let dataNotPosted = 0
        const tempData = []

        const contractPromises = []
        let contractUploadCount = 0
        const totalContracts = _.size(vm.contractContents)
        const promises = await vm.contractContents.reduce(async (memo, currentValue, currentIndex, array) => {
          await memo
          const response = vm.submitEachContract(currentValue)
          var myPromise = this.MakeQuerablePromise(response)
          response.then(function (data) {
            if (myPromise.isFulfilled()) {
              contractUploadCount += 1
              vm.uploadPercentage = Math.ceil(contractUploadCount / totalContracts * 100)
            }
          })
          contractPromises.push(response)
        }, [])
        _.unset(promises, '')

        Promise.all(contractPromises).then((values) => {
          values.map((item) => {
            const data = item.data
            if (data.success) {
              if (!_.get(data, 'results')) {
                tempData.push(data)
              } else {
                this.$store.commit('setTraceabilityContractsContent', data.results)
              }
              dataPosted += 1
            } else {
              this.$notify({
                title: 'Contract',
                text: data.message,
                type: 'error', // 'warn', 'error', 'success'
                duration: 10000
              })
              dataNotPosted += 1
              vm.errorLog(item)
            }
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'

          if (tempData.length > 0) {
            vm.$store.commit('setTraceabilityContractsContent', tempData)
            this.getTableData(tempData)
          }
          if (dataNotPosted > 0) {
            this.$notify({
              title: 'Contract',
              text: `${dataNotPosted} contract not created/ updated.`,
              type: 'warn', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
          if (dataPosted > 0) {
            this.$notify({
              title: 'Contract',
              text: `${dataPosted} contract created/ updated successfully.`,
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          }

          // Email sending to respective Customers, Exporters, Importers
          const customerContracts = _.filter(tempData, function (item) {
            const customer = _.get(item, 'customer') || null
            if (customer) {
              if (!_.get(item, 'notification_sent')) return customer
            }
          })
          const exporterContracts = _.filter(tempData, function (item) {
            const exporter = _.get(item, 'exporter') || null
            if (exporter) {
              if (!_.get(item, 'notification_sent')) return exporter
            }
          })
          const importerContracts = _.filter(tempData, function (item) {
            const importer = _.get(item, 'importer') || null
            if (importer) {
              if (!_.get(item, 'notification_sent')) return importer
            }
          })

          const uniqCustomers = _.map(_.uniqBy(customerContracts, 'customer.public_code'), 'customer.public_code')
          _.map(uniqCustomers, function (item) {
            vm.contractImportObject[item] = _.filter(customerContracts, function (customerObj) {
              if (_.get(customerObj, 'customer.public_code') === item) return customerObj
            }).length
          })
          const uniqExporters = _.map(_.uniqBy(exporterContracts, 'exporter.public_code'), 'exporter.public_code')
          _.map(uniqExporters, function (item) {
            vm.contractImportObject[item] = _.filter(exporterContracts, function (exporterObj) {
              if (_.get(exporterObj, 'exporter.public_code') === item) return exporterObj
            }).length
          })
          const uniqImporters = _.map(_.uniqBy(importerContracts, 'importer.public_code'), 'importer.public_code')
          _.map(uniqImporters, function (item) {
            vm.contractImportObject[item] = _.filter(importerContracts, function (importerObj) {
              if (_.get(importerObj, 'importer.public_code') === item) return importerObj
            }).length
          })

          vm.sendEmail(vm.contractImportObject)
        })
      }
    },
    async submitEachContract (data) {
      const formData = this.$_.cloneDeep(data)
      return await this.$store.dispatch('createOrUpdateTraceabilityContractsContent', formData)
    },
    getTableData (payload) {
      if (payload) {
        payload = this.$store.getters.getTraceabilityAllCountryContractsContents
      }
      this.tableData = []
      for (const _data of payload) {
        const tempData = []
        tempData.push(_.get(_data, 'customer.public_code') || 'N/A')
        tempData.push(_.get(_data, 'customer.name') || 'N/A')
        tempData.push(_.get(_data, 'importer.public_code') || 'N/A')
        tempData.push(_.get(_data, 'importer.name') || 'N/A')
        tempData.push(_.get(_data, 'contract_no') || 'N/A')

        const shipmentStart = _.get(_data, 'shipment_start_date') || null
        const shipmentEnd = _.get(_data, 'shipment_end_date') || null
        if (shipmentStart && shipmentEnd) {
          tempData.push(moment(shipmentStart).format('DD.MM.YYYY') + ' - ' + moment(shipmentEnd).format('DD.MM.YYYY'))
        } else {
          tempData.push('N/A')
        }

        tempData.push(_.get(_data, 'exporter.public_code') || 'N/A')
        tempData.push(_.get(_data, 'exporter.name') || 'N/A')
        tempData.push(_.get(_data, 'country_code') || 'N/A')
        tempData.push(_.get(_data, 'country_name') || 'N/A')
        tempData.push(_.get(_data, 'weight') || 'N/A')
        tempData.push(_.get(_data, 'bloom_share') || 'N/A')
        tempData.push(_.get(_data, 'contract_quality') || 'N/A')
        tempData.push(_.get(_data, 'co_invest_farmer') || 'N/A')
        tempData.push(_.get(_data, 'co_invest_fsu') || 'N/A')

        this.tableData.push(tempData)
      }
      return this.tableData
    },
    async createFileUploadLog (data) {
      const formData = this.$_.cloneDeep(data)
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : this.uuidV4()
      this.fileTsyncIdMapping[fileTsyncId] = data.file.file
      if (this.contractForm.file.file) {
        if (!formData.id) {
          formData.file = {
            tsync_id: fileTsyncId,
            extension: formData.file.name.split('.').pop()
          }
        } else {
          try {
            formData.file.extension = formData.file.name.split('.').pop()
          } catch (e) {
            this.errorLog(e)
          }
        }
      }
      formData.content_type = ContentTypes.FILE
      formData.content = this.$store.getters.getContentByAppModule(Menus.traceability.key).id
      return await this.$store.dispatch('createOrUpdateTraceabilityContents', formData)
    },
    async sendEmail (contracts) {
      const url = CommonHelper.makePublicApiUrl('contract-import-notification')
      const payload = {
        imported_contracts: contracts,
        contract_type: 'original'
      }
      try {
        const response = await APIHelper.postData(url, payload)
        const data = response.data
        if (data.success) {
          this.isSaveButtonActive = true
          this.$notify({
            title: 'Email sent.',
            text: 'Password reset email sent.',
            type: 'success',
            duration: 5000
          })
          this.$v.$reset()
        } else {
          this.isSaveButtonActive = true
          this.errorResponse.message = _.get(data, 'status_message') || ''
          if (this.errorResponse.message) {
            this.$notify({
              title: '',
              text: this.errorResponse.message,
              type: 'error',
              duration: 5000
            })
          }
        }
      } catch (e) {
        return e
      }
    }
  },
  watch: {
    contractsContent: {
      deep: true,
      handler (newValue) {
        this.contracts = _.cloneDeep(newValue)
      }
    }
  },
  created () {
    window.onbeforeunload = () => (this.uploadPercentage ? true : null)
  }
}
</script>

<style lang="scss" scoped>

.clear-file {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 100%; // if file name is long than it wont overflow next close button
}
</style>

<template>
  <Traceability></Traceability>
</template>

<script>
import Traceability from '@/components/admin/traceability/Traceability'

export default {
  name: 'TraceabilityView',
  components: {
    Traceability
  }
}
</script>

<style scoped>

</style>
